<template>
  <DetailTemplate
    :custom-class="'ticket-detail detail-page'"
    v-if="getPermission('visit:view')"
  >
    <template v-slot:header-title>
      <div class="d-flex align-center flex-wrap" v-if="pageLoaded">
        <span
          class="color-custom-blue font-weight-700 font-size-20 text-truncate mr-2"
          style=""
          >{{ ticket.title }}
        </span>
        <v-chip
          label
          color="chip-custom-blue"
          outlined
          class="text-white p-3"
          style="font-size: 21px !important; font-weight: 700"
          >{{ visit.barcode
          }}<!-- <template v-if="visit && visit.reassign_count > 0">
         
             <div>-0{{ visit.reassign_count }}</div>
          
        </template> -->
        </v-chip>
        <Priority :priority="detail.priority"></Priority>

        <CustomStatus
          :status="visit.status"
          endpoint="delivery/status"
        ></CustomStatus>
        <v-chip
          v-if="
            visit.is_rejeced == 1 &&
            visit.engineers &&
            visit.engineers.length == 0
          "
          label
          color="red"
          outlined
          text-color=""
          class="p-3 mr-5"
          >Re-assignment Pending</v-chip
        >
        <!--   <v-tooltip top content-class="custom-top-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              v-on="on"
              v-if="visit.status == 7"
              class="mx-2 custom-status font-weight-600 custom-grey-border d-inline-table text-uppercase justify-center font-10 text-white"
              color="#66bb6a"
              label
            >
              Completed
            </v-chip>
          </template>
          <span>Completed</span>
        </v-tooltip> -->
        <!--  <v-tooltip top content-class="custom-top-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              v-on="on"
              class="mx-2 d-inline-table font-weight-600"
              color="chip-custom-blue"
              label
              outlined
            >
              {{ getScheduleType }}
            </v-chip>
          </template>
          <span>Schedule Type</span>
        </v-tooltip> -->
        <!--   <v-tooltip top content-class="custom-top-tooltip">
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              v-on="on"
              v-if="visit_reschdule_count > 0"
              class="mx-2 custom-status font-weight-600 custom-grey-border d-inline-table text-uppercase justify-center font-10 text-white"
              color="red"
              label
            >
              Rivision ({{ visit_reschdule_count }})
            </v-chip>
          </template>
          <span>Rivision ({{ visit_reschdule_count }})</span>
        </v-tooltip>

        <v-tooltip
          top
          content-class="custom-top-tooltip"
          v-if="parseInt(visit.follow_up) && follow_up_route != 'follow-up'"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              v-bind="attrs"
              v-on="on"
              class="mx-2 d-inline-table font-weight-600"
              :color="`${getFollowColor(visit.followup[0].status)} white--text`"
              label
              outlined
            >
              Follow Up
              <template
                v-if="
                  visit && visit.followup[0] && visit.followup[0].status == 2
                "
                >Closed</template
              >
            </v-chip>
          </template>
          <span>Follow Up</span>
        </v-tooltip> -->
      </div>
    </template>

    <template v-slot:header-action>
      <div class="d-flex align-center justify-end flex-wrap" v-if="pageLoaded">
        <!--  <template>
          <v-btn
            v-if="follow_up_route != 'follow-up'"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            v-on:click="share_dialog = true"
          >
            <v-icon left>mdi-share</v-icon>
            Share Service Report
          </v-btn>
        </template> -->
        <v-btn
          v-if="
            visit.status != 7 &&
            visit.engineers &&
            visit.engineers.length == 0 &&
            visit.status != 2
          "
          class="mx-2 mb-1 custom-bold-button white--text"
          color="cyan"
          :disabled="timerLoading || visitLoading"
          :loading="timerLoading"
          v-on:click="reassignScheduleDialog"
        >
          Assign
        </v-btn>
        <v-btn
          class="mx-2 custom-bold-button white--text"
          color="cyan"
          v-on:click="acceptItem"
          v-if="
            visit.is_accepted == 0 &&
            visit.engineers &&
            visit.engineers.length > 0 &&
            visit.status != 2
          "
        >
          Accept
        </v-btn>
        <v-btn
          class="mx-2 custom-bold-button white--text"
          color="red darken-4"
          v-on:click="failedDialog('failed')"
          v-if="
            !isDelivere &&
            visit.engineers &&
            visit.engineers.length > 0 &&
            visit.status != 7 &&
            visit.status != 2 &&
            visit.status != 3 &&
            visit.is_accepted != 0 &&
            visit.sub_delivery_count == 0
          "
        >
          Fail
        </v-btn>
        <!--  <v-btn
          class="mx-2 custom-bold-button white--text"
          color="green"
          v-if="
            !isDelivere &&
            visit.status != 7 &&
            visit.is_accepted == 1 &&
            visit.engineers &&
            visit.engineers.length > 0 &&
            visit.status != 2
          "
          v-on:click="failedDialog('full')"
        >
          Fully Delivered
        </v-btn> -->
        <!-- <v-tooltip  left content-class="custom-top-tooltip">
          <template v-slot:activator="{ on, attrs }"> -->
        <v-btn
          class="mx-2 custom-bold-button white--text"
          color="green"
          v-on:click="deliveredItem"
          v-if="
            !isDelivere &&
            visit.engineers &&
            visit.engineers.length > 0 &&
            visit.status != 7 &&
            visit.status != 2 &&
            visit.is_accepted != 0
          "
        >
          <v-icon color="white darken-4" left>mdi-truck</v-icon> Delivery
        </v-btn>
        <!--   </template>
           <span>Create Delivery</span>
        </v-tooltip>  -->
        <v-btn
          class="mx-2 custom-bold-button white--text"
          color="blue"
          v-if="isDelivere"
          v-on:click="saveDeliveryItem('partial')"
        >
          save
        </v-btn>
        <v-btn
          class="mx-2 mb-1 custom-grey-border custom-bold-button"
          v-if="isDelivere"
          v-on:click="isDelivere = false"
        >
          cancel
        </v-btn>
        <template v-if="visit.status == 1">
          <v-btn
            :disabled="isDelivere"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            v-on:click="routeToUpdate()"
          >
            <v-icon left>mdi-pencil</v-icon>
            Edit
          </v-btn>
        </template>
        <v-menu
          v-if="visit.status == 6 && false"
          transition="slide-y-transition"
          bottom
          content-class="custom-menu-list"
          offset-y
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="statusLoading || isDelivere"
              class="mx-2 mb-1 custom-bold-button white--text"
              color="cyan"
              v-bind="attrs"
              v-on="on"
            >
              More... <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template v-for="(more, index) in moreActions">
              <v-list-item
                v-if="more.action"
                link
                v-on:click="updateMoreAction(more.action)"
                :key="index"
                :disabled="statusLoading || more.disabled"
              >
                <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                  <v-icon class="icon-default-blue">{{ more.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-500 font-size-14">{{
                  more.title
                }}</v-list-item-title>
              </v-list-item>
            </template>

            <!-- <template v-if="closerdata.accept_term == 0 && is_staff">
              <v-list-item link v-on:click="getSignatureLink()" :key="2">
                <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                  <v-icon class="icon-default-blue">mdi-signature-freehand</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-500 font-size-14">
                  Signature Link
                </v-list-item-title>
              </v-list-item>
            </template>

            <template v-if="closerdata.accept_term == 0 && is_staff">
              <v-list-item link v-on:click="mail_dialog = true" :key="3">
                <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                  <v-icon class="icon-default-blue">mdi-email-send</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="font-weight-500 font-size-14">
                  Send as Email
                </v-list-item-title>
              </v-list-item>
            </template>  -->
          </v-list>
        </v-menu>
        <template v-if="engineerList.length">
          <v-menu
            v-model="engineer_menu"
            bottom
            left
            offset-y
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2 mb-1 custom-bold-button white--text"
                color="cyan"
                :disabled="apptEngLoading"
                :loading="apptEngLoading"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon class="mr-2">mdi-account-group</v-icon>Assign
              </v-btn>
            </template>
            <v-list class="light-gray-background pb-0" min-width="340">
              <v-list-item-title
                class="text-uppercase font-weight-700 color-custom-blue font-size-16 p-2 pt-0"
              >
                Select Team
              </v-list-item-title>
              <perfect-scrollbar
                :options="{ suppressScrollX: true }"
                class="scroll custom-box-top-inner-shadow"
                style="max-height: 50vh; position: relative"
              >
                <v-list-item
                  class="white-background"
                  v-for="(team, index) in engineerList"
                  :key="index"
                >
                  <v-checkbox
                    v-model="assigned_team"
                    :label="team.display_name"
                    :value="team.id"
                    hide-details
                    color="cyan"
                    class="mr-4 mt-0"
                    :disabled="apptEngLoading"
                    multiple
                  >
                    <template v-slot:label>
                      <v-list-item>
                        <v-list-item-avatar>
                          <v-img
                            :lazy-src="$defaultProfileImage"
                            :src="$assetAPIURL(team.profile_logo)"
                          ></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-tooltip top content-class="custom-top-tooltip">
                            <template v-slot:activator="{ on, attrs }">
                              <v-list-item-title
                                v-bind="attrs"
                                v-on="on"
                                class="font-size-16 font-weight-500"
                                v-html="team.display_name"
                              ></v-list-item-title>
                            </template>
                            <span class="text-capitalize">
                              <template v-if="team.skills">{{
                                team.skills.join(", ")
                              }}</template>
                              <template v-else-if="team.designation">{{
                                team.designation.value
                              }}</template>
                              <template v-else><em>No Skills</em></template>
                            </span>
                          </v-tooltip>
                          <v-list-item-subtitle
                            v-if="lodash.isEmpty(team.designation) == false"
                            >{{ team.designation.value }}</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-checkbox>
                </v-list-item>
              </perfect-scrollbar>
              <v-btn
                :disabled="apptEngLoading"
                :loading="apptEngLoading"
                v-on:click="updateEngineer()"
                color="cyan white--text"
                class="w-100 text-uppercase font-weight-700 font-size-16 mb-1"
                >Save</v-btn
              >
            </v-list>
          </v-menu>
        </template>
        <template v-if="engineerList.length && false">
          <v-menu
            v-model="engineer_menu"
            bottom
            left
            offset-y
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2 mb-1 custom-bold-button white--text"
                color="cyan"
                :disabled="apptEngLoading"
                :loading="apptEngLoading"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon class="mr-2">mdi-account-group</v-icon>Assign
              </v-btn>
            </template>
            <v-list class="light-gray-background pb-0" min-width="340">
              <v-list-item-title
                class="text-uppercase font-weight-700 color-custom-blue font-size-16 p-2 pt-0"
              >
                Select Driver
              </v-list-item-title>
              <perfect-scrollbar
                :options="{ suppressScrollX: true }"
                class="scroll custom-box-top-inner-shadow"
                style="max-height: 50vh; position: relative"
              >
                <v-list-item
                  class="white-background"
                  v-for="(team, index) in engineerList"
                  :key="index"
                >
                  <!-- <v-checkbox
                    v-model="assigned_team"
                    :label="team.display_name"
                    :value="team.id"
                    hide-details
                    color="cyan"
                    class="mr-4 mt-0"
                    :disabled="apptEngLoading"
                    multiple
                  > -->
                  <v-radio-group
                    v-model="assigned_team"
                    :label="team.display_name"
                    row
                    hide-details
                    :disabled="apptEngLoading"
                    class="ml-3 my-3"
                  >
                    <v-radio :value="team.id" color="cyan" class="mr-10">
                      <template v-slot:label>
                        <v-list-item>
                          <v-list-item-avatar>
                            <v-img
                              :lazy-src="$defaultProfileImage"
                              :src="$assetAPIURL(team.profile_logo)"
                            ></v-img>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title
                              class="font-size-16 font-weight-500"
                              v-html="`${team.team_name} (${team.barcode})`"
                            ></v-list-item-title>

                            <!--  <span class="text-capitalize">
                                <template v-if="team.skills">{{
                                  team.skills.join(", ")
                                }}</template>
                                <template v-else-if="team.designation">{{
                                  team.designation.value
                                }}</template>
                                <template v-else><em>No Skills</em></template>
                              </span> -->

                            <v-list-item-subtitle
                              v-if="lodash.isEmpty(team.designation) == false"
                              >{{
                                team.designation.value
                              }}</v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-radio>
                  </v-radio-group>
                </v-list-item>
              </perfect-scrollbar>
              <v-btn
                :disabled="apptEngLoading"
                :loading="apptEngLoading"
                v-on:click="updateEngineer()"
                color="cyan white--text"
                class="w-100 text-uppercase font-weight-700 font-size-16 mb-1"
                >Save</v-btn
              >
            </v-list>
          </v-menu>
        </template>
        <v-btn
          :disabled="isDelivere"
          class="mx-2 mb-1 custom-grey-border custom-bold-button"
          v-on:click="goBack()"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </div>
    </template>
    <template v-slot:body>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll"
        style="height: calc(100vh - 110px); position: relative"
      >
        <v-container fluid>
          <v-row v-if="pageLoaded">
            <v-col md="6">
              <span class="color-custom-blue font-weight-700 font-size-19">
                <router-link
                  :to="
                    getDefaultRoute('customer.detail', {
                      params: {
                        id: detail.customer.id,
                      },
                    })
                  "
                  >{{ detail.customer.display_name }}
                </router-link>
              </span>
              <v-layout class="my-4">
                <v-flex md4 class="ml-2">
                  <table width="100%">
                    <tr>
                      <td class="font-weight-600">
                        Billing Address
                        <!-- <pre>{{serviceformdata.length}}</pre> -->
                      </td>
                    </tr>
                    <tr
                      v-if="
                        detail &&
                        detail.billing &&
                        detail.billing.property_address
                      "
                    >
                      <td class="py-0 font-size-18 font-weight-500">
                        <p
                          v-if="
                            detail &&
                            detail.billing &&
                            detail.billing.property_address
                          "
                        >
                          {{ detail.billing.street_1 }},
                          <br
                            v-if="
                              detail.billing.street_2 || detail.billing.unit_no
                            "
                          />
                          <template v-if="detail.billing.street_2">
                            {{ detail.billing.street_2 }},
                          </template>
                          <template v-if="detail.billing.unit_no">
                            {{ detail.billing.unit_no }},
                          </template>
                          <br />
                          {{ detail.billing.country }}
                          <template
                            v-if="
                              detail.billing.zip_code &&
                              detail.billing.zip_code != '000000'
                            "
                          >
                            , {{ detail.billing.zip_code }}
                          </template>
                        </p>
                        <!--  <a
                          target="_blank"
                          :href="`https://maps.google.com/?q=${detail.billing.property_address}`"
                          >{{ detail.billing.property_address }}</a
                        > -->
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-600">Contact details</td>
                    </tr>
                    <tr v-if="detail.billing_person">
                      <td class="py-0 font-size-18 font-weight-500">
                        <template
                          v-if="
                            detail &&
                            detail.billing_person &&
                            detail.billing_person.display_name
                          "
                        >
                          {{ detail.billing_person.display_name }}
                        </template>
                      </td>
                    </tr>
                    <tr v-if="detail.billing_person">
                      <td class="py-0 font-size-18 font-weight-500">
                        <template
                          v-if="
                            detail &&
                            detail.billing_person &&
                            detail.billing_person.primary_phone
                          "
                        >
                          {{ detail.billing_person.primary_phone }}
                        </template>
                      </td>
                    </tr>
                    <tr v-if="detail.billing_person">
                      <td class="py-0 font-size-18 font-weight-500">
                        <template
                          v-if="
                            detail &&
                            detail.billing_person &&
                            detail.billing_person.primary_email
                          "
                        >
                          {{ detail.billing_person.primary_email }}
                        </template>
                        <v-icon
                          v-if="detail.billing_person_notify"
                          v-tippy="{
                            arrow: true,
                            arrowType: 'round',
                            animation: 'fade',
                          }"
                          content="Notification was sent to given email"
                          color="green lighten-1"
                          >mdi-check</v-icon
                        >
                      </td>
                    </tr>
                  </table>
                </v-flex>
                <v-flex md4 class="mr-2 custom-border-right">
                  <table width="100%">
                    <tr>
                      <td class="font-weight-600">Delivery Location</td>
                    </tr>
                    <tr
                      v-if="
                        detail &&
                        detail.property &&
                        detail.property.property_address
                      "
                    >
                      <td class="py-0 font-size-18 font-weight-500">
                        <p
                          v-if="
                            detail &&
                            detail.property &&
                            detail.property.property_address
                          "
                        >
                          {{ detail.property.street_1 }},
                          <br
                            v-if="
                              detail.property.street_2 ||
                              detail.property.unit_no
                            "
                          />
                          <template v-if="detail.property.street_2">
                            {{ detail.property.street_2 }},
                          </template>
                          <template v-if="detail.property.unit_no">
                            {{ detail.property.unit_no }},
                          </template>
                          <br />
                          {{ detail.property.country }}
                          <template
                            v-if="
                              detail.property.zip_code &&
                              detail.property.zip_code != '000000'
                            "
                          >
                            , {{ detail.property.zip_code }},
                          </template>
                        </p>
                        <!-- <a
                          target="_blank"
                          :href="`https://maps.google.com/?q=${detail.property.property_address}`"
                          >{{ detail.property.property_address }}</a
                        > -->
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-600">Contact details</td>
                    </tr>
                    <tr v-if="detail.property_person">
                      <td class="py-0 font-size-18 font-weight-500">
                        <template
                          v-if="
                            detail &&
                            detail.property_person &&
                            detail.property_person.display_name
                          "
                        >
                          {{ detail.property_person.display_name }}
                        </template>
                      </td>
                    </tr>
                    <tr v-if="detail.property_person">
                      <td class="py-0 font-size-18 font-weight-500">
                        <template
                          v-if="
                            detail &&
                            detail.property_person &&
                            detail.property_person.primary_phone
                          "
                        >
                          {{ detail.property_person.primary_phone }}
                        </template>
                      </td>
                    </tr>
                    <!-- <tr v-if="detail.property_person">
                      <td class="py-0 font-size-18 font-weight-500">
                        <template v-if="detail && detail.property_person && detail.property_person.primary_phone">
                          {{ detail.property_person.primary_phone }}
                        </template>

                      </td>
                    </tr> -->
                    <tr v-if="detail.property_person">
                      <td class="py-0 font-size-18 font-weight-500">
                        <template
                          v-if="
                            detail &&
                            detail.property_person &&
                            detail.property_person.primary_email
                          "
                        >
                          {{ detail.property_person.primary_email }}
                        </template>
                        <v-icon
                          v-if="detail.property_person_notify"
                          v-tippy="{
                            arrow: true,
                            arrowType: 'round',
                            animation: 'fade',
                          }"
                          content="Notification was sent to given email"
                          color="green lighten-1"
                          >mdi-check</v-icon
                        >
                      </td>
                    </tr>
                  </table>
                </v-flex>

                <v-flex md4 class="ml-2">
                  <table width="100%">
                    <tr>
                      <td class="font-weight-600">
                        Pickup Address
                        <!-- <pre>{{serviceformdata.length}}</pre> -->
                      </td>
                    </tr>
                    <tr v-if="visit && visit.pic_address">
                      <td class="py-0 font-size-18 font-weight-500">
                        <!--  <template v-if="visit.pic_address.street_1"> {{ visit.pic_address.street_1 }} <br></template>
                              <template v-if="visit.pic_address.street_2">{{ visit.pic_address.street_2 }},</template>
                              <template v-if="visit.pic_address.unit_no">{{ visit.pic_address.unit_no }},<br></template>
                              <template v-if="visit.pic_address.country">{{ visit.pic_address.country }},</template>
                              <template v-if="visit.pic_address.zip_code">{{ visit.pic_address.zip_code }}</template> -->
                        <a
                          target="_blank"
                          :href="`https://maps.google.com/?q=${getFormattedBilling(
                            visit.pic_address
                          )}`"
                          >{{ getFormattedBilling(visit.pic_address) }}</a
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-600">Contact details</td>
                    </tr>
                    <tr v-if="visit.user_data">
                      <td class="py-0 font-size-18 font-weight-500">
                        <template
                          v-if="
                            visit &&
                            visit.user_data &&
                            visit.user_data.display_name
                          "
                        >
                          {{ visit.user_data.display_name }}
                        </template>
                      </td>
                    </tr>
                    <tr v-if="visit.user_data">
                      <td class="py-0 font-size-18 font-weight-500">
                        <template
                          v-if="
                            detail &&
                            visit.user_data &&
                            visit.user_data.phone_number
                          "
                        >
                          {{ visit.user_data.phone_number }}
                        </template>
                      </td>
                    </tr>
                    <tr v-if="visit.user_data">
                      <td class="py-0 font-size-18 font-weight-500">
                        <template
                          v-if="
                            detail &&
                            visit.user_data &&
                            visit.user_data.user_email
                          "
                        >
                          {{ visit.user_data.user_email }}
                        </template>
                        <v-icon
                          v-tippy="{
                            arrow: true,
                            arrowType: 'round',
                            animation: 'fade',
                          }"
                          content="Notification was sent to given email"
                          color="green lighten-1"
                          >mdi-check</v-icon
                        >
                      </td>
                    </tr>
                  </table>
                </v-flex>
              </v-layout>
            </v-col>
            <v-col md="6" class="custom-border-left gray-background">
              <table width="100%">
                <tr>
                  <td
                    valign="middle"
                    class="py-1 font-size-18 font-weight-500"
                    colspan="2"
                  >
                    <span
                      class="color-custom-blue font-weight-700 font-size-19"
                      >{{ ticket.title }}</span
                    >
                  </td>
                </tr>
                <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Drivers
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <template v-if="teamEngineers && teamEngineers.length > 0">
                      <v-chip
                        class="mb-1 mr-1"
                        v-for="(row, index) in teamEngineers"
                        :key="index"
                        ><v-avatar left small>
                          <img
                            v-if="
                              row &&
                              row.engineer &&
                              row.engineer.profile_logo &&
                              row.engineer.profile_logo.file &&
                              row.engineer.profile_logo.file.url
                            "
                            :src="row.engineer.profile_logo.file.url"
                          />
                          <img
                            v-else
                            src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
                          />
                        </v-avatar>
                        <span
                          v-if="
                            row && row.engineer && row.engineer.display_name
                          "
                          >{{ row.engineer.display_name }}</span
                        >
                      </v-chip>
                    </template>
                    <em v-else class="text-muted"> no drivers</em>
                  </td>
                </tr>
                <!-- <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Delivery #
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{ visit.barcode }}</label>
                  </td>
                </tr> -->
                <tr v-if="visit.contract_detail">
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Contract #
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">
                      <Barcode
                        route="contract.detail"
                        :barcode="visit.contract_detail.barcode"
                        :id="visit.contract_detail.id"
                      ></Barcode>
                    </label>
                  </td>
                </tr>
                <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Reference #
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0" v-if="detail.reference">{{
                      detail.reference
                    }}</label>
                    <em v-else class="text-muted"> no reference</em>
                  </td>
                </tr>
                <tr v-if="detail && detail.old_do">
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Old delivery
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0" v-if="detail && detail.old_do">{{
                      detail.old_do
                    }}</label>
                    <em v-else class="text-muted"> no old delivery</em>
                  </td>
                </tr>
                <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Job Type
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">Delivery</label>
                  </td>
                </tr>
                <tr v-if="visit.payment_type">
                  <td valign="top" class="py-1 font-size-18 font-weight-600">
                    Payment Type
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0 text-capitalize">
                      <template v-if="visit.payment_type == 'cash-on-delivery'">
                        <v-chip
                          outlined
                          label
                          style="height: 26px"
                          class="mr-2"
                          color="green white--text"
                        >
                          Cash On Delivery
                        </v-chip>
                      </template>
                      <template v-if="visit.payment_type == 'prepaid'">
                        <v-chip
                          outlined
                          style="height: 26px"
                          class="mr-2"
                          color="cyan white--text"
                        >
                          Prepaid
                        </v-chip></template
                      >
                      <template v-if="visit.payment_type == 'postpaid'">
                        <v-chip
                          outlined
                          style="height: 26px"
                          class="mr-2"
                          color="red white--text"
                        >
                          Postpaid
                        </v-chip></template
                      >
                    </label>
                  </td>
                </tr>
                <tr
                  v-if="
                    detail && detail.payment_mode && detail.payment_mode.text
                  "
                >
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Payment Mode
                  </td>
                  <td
                    valign="middle"
                    class="py-1 font-size-18 font-weight-500"
                    v-if="
                      detail && detail.payment_mode && detail.payment_mode.text
                    "
                  >
                    <label class="m-0">{{ detail.payment_mode.text }}</label>
                  </td>
                </tr>
                <!-- <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    PO Number
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{ detail.po_number }}</label>
                  </td>
                </tr> -->
                <!-- <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Payment Mode
                  </td>
                  <td
                    valign="middle"
                    class="py-1 font-size-18 font-weight-500"
                    v-if="
                      detail && detail.payment_mode && detail.payment_mode.text
                    "
                  >
                    <label class="m-0">{{ detail.payment_mode.text }}</label>
                  </td>
                </tr> -->

                <!-- <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Customer Type
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{
                    visit.visit_customer_type
                    }}</label>
                  </td>
                </tr>
                <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Quotation No
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{ visit.quotation }}</label>
                  </td>
                </tr> -->
                <!-- <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Chargable
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500" v-if="runningAmount.total_charge">
                    <label class="m-0">{{ runningAmount.total_charge }}</label>
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600" v-else>
                    <em class="text--secondary">No chargable </em>
                  </td>
                </tr> -->
                <!-- <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Customer Type
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500" v-if="visit.visit_dlp">
                    <label class="m-0">{{ visit.visit_dlp }}</label>
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600" v-else>
                    <em class="text--secondary">No Customer Type </em>
                  </td>
                </tr> -->
                <!--  <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Payment Mode
                  </td>
                  <td
                    valign="middle"
                    class="py-1 font-size-18 font-weight-500"
                    v-if="visit.payment_mode"
                  >
                    <label class="m-0">{{ visit.payment_mode }}</label>
                  </td>
                  <td
                    valign="middle"
                    class="py-1 font-size-18 font-weight-600"
                    v-else
                  >
                    <em class="text--secondary">Not Selected</em>
                  </td>
                </tr>
                <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Collected payment
                  </td>
                  <td
                    valign="middle"
                    class="py-1 font-size-18 font-weight-500"
                    v-if="visit.collected_payment"
                  >
                    <label class="m-0">{{ visit.collected_payment }}</label>
                  </td>
                  <td
                    valign="middle"
                    class="py-1 font-size-18 font-weight-600"
                    v-else
                  >
                    <em class="text--secondary">Amount Not Received Yet</em>
                  </td>
                </tr> -->
                <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Delivery Date
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    {{ formatDate(visit.started_at) }}
                    <template v-if="getStartTime"
                      >{{ getStartTime }} - {{ getEndTime }}</template
                    >
                    <template v-else>12:00 AM - 11:59 PM</template>
                  </td>
                </tr>
                <tr v-if="visit.delivery_date && visit.status == 7">
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Actual Date
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    {{ formatDate(visit.delivery_date) }}
                    {{ formatDateStartTime(visit.delivery_date) }}
                  </td>
                </tr>
                <tr v-if="detail.schedule_message">
                  <td valign="top" class="py-1 font-size-18 font-weight-600">
                    Schedule
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0">{{ detail.schedule_message }}</label>
                  </td>
                </tr>
                <tr v-if="visit.cancel_reason_type">
                  <td
                    valign="top"
                    class="py-1 font-size-18 font-weight-600 d-flex"
                  >
                    Rejected Reason
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0 text-capitalize">{{
                      visit.cancel_reason_type.replace("-", " ")
                    }}</label>
                  </td>
                </tr>
                <tr v-if="visit.cancel_reason">
                  <td
                    valign="top"
                    class="py-1 font-size-18 font-weight-600 d-flex"
                  >
                    Rejected Remark
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0 text-capitalize">{{
                      visit.cancel_reason
                    }}</label>
                  </td>
                </tr>
                <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Tag
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <template v-if="detail.dtags && detail.dtags.length">
                      <v-chip
                        v-for="(row, index) in detail.dtags"
                        :key="index"
                        style="height: 26px"
                        class="mr-2"
                        text-color="white"
                        :color="row.color"
                        >{{ row.text }}</v-chip
                      >
                    </template>
                    <em v-else class="text-muted"> no tags</em>
                  </td>
                </tr>
              </table>
              <v-tooltip
                v-for="(type, index) in detail.ticket_type"
                :key="'ticket-type' + index"
                top
                content-class="custom-top-tooltip"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-chip
                    v-bind="attrs"
                    v-on="on"
                    class="ma-2 font-weight-600"
                    color="chip-custom-blue"
                    label
                    small
                    outlined
                  >
                    {{ type }}
                  </v-chip>
                </template>
                <span>Job Type</span>
              </v-tooltip>
            </v-col>
            <v-col md="12">
              <v-tabs
                active-class="custom-tab-active"
                v-model="ticketTab"
                background-color="transparent"
                color="cyan"
                class="custom-tab-transparent tab-sticky"
              >
                <v-tab
                  v-if="follow_up_route == 'follow-up' && false"
                  class="font-size-16 font-weight-600 px-8"
                  href="#chatNotes"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/visit.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Follow Up Notes
                </v-tab>
                <v-tab
                  class="font-size-16 font-weight-600 px-8"
                  href="#other"
                  :disabled="isDelivere"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/visit.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Overview
                </v-tab>
                <v-tab
                  class="font-size-16 font-weight-600 px-8"
                  href="#line-item"
                  :disabled="isDelivere"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/line-item.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Line Items
                </v-tab>
                <v-tab
                  class="font-size-16 font-weight-600 px-8"
                  href="#pending"
                  v-if="visit.status != 7"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/line-item.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Pending
                </v-tab>
                <v-tab
                  class="font-size-16 font-weight-600 px-8"
                  href="#received"
                  :disabled="isDelivere"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/line-item.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Received
                </v-tab>
                <v-tab
                  class="font-size-16 font-weight-600 px-8"
                  href="#deliveries"
                  :disabled="isDelivere"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/line-item.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  <span style="text-transform: none !important">ePOD</span>/
                  Deliveries
                </v-tab>
                <v-tab
                  class="font-size-16 font-weight-600 px-8"
                  href="#team"
                  v-if="false"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/engineer.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Driver
                </v-tab>
                <v-tab
                  v-if="false"
                  class="font-size-16 font-weight-600 px-8"
                  href="#service-form"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/service-form.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Service Form
                </v-tab>
                <v-tab
                  class="font-size-16 font-weight-600 px-8"
                  href="#closer"
                  v-if="false"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/closer.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Closer
                </v-tab>
                <v-tab
                  :disabled="isDelivere"
                  class="font-size-16 font-weight-600 px-8"
                  href="#notes"
                  v-if="follow_up_route != 'follow-up'"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg :src="$assetURL('media/custom-svg/note.svg')" />
                    <!--end::Svg Icon-->
                  </span>
                  Notes
                </v-tab>
                <!--<v-tab
                  class="font-size-16 font-weight-600 px-8"
                  href="#invoice"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    
                    <inline-svg
                      :src="$assetURL('media/custom-svg/invoice.svg')"
                    />
                   
                  </span>
                  Invoices
                </v-tab>
                <v-tab
                  class="font-size-16 font-weight-600 px-8"
                  href="#quotation"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                 
                    <inline-svg
                      :src="$assetURL('media/custom-svg/quotation.svg')"
                    />
                   
                  </span>
                  Quotation
                </v-tab>-->
                <v-tab
                  class="font-size-16 font-weight-600 px-8"
                  href="#history"
                  :disabled="isDelivere"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/history.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  History
                </v-tab>
                <v-tab
                  v-if="false"
                  class="font-size-16 font-weight-600 px-8"
                  href="#timestamp"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="$assetURL('media/custom-svg/engineer.svg')"
                    />
                    <!--end::Svg Icon-->
                  </span>
                  Timestamp
                </v-tab>
                <v-tab
                  v-if="false"
                  class="font-size-16 font-weight-600 px-8"
                  href="#available-tickets"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <inline-svg
                      :src="$assetURL('media/custom-svg/engineer.svg')"
                    />
                  </span>
                  Available Tickets
                </v-tab>
                <v-tab
                  v-if="visit.status == 4 && visit.follow_up == 1 && false"
                  class="font-size-16 font-weight-600 px-8"
                  href="#followup"
                >
                  <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                    <inline-svg
                      :src="$assetURL('media/custom-svg/engineer.svg')"
                    />
                  </span>
                  Follow Up
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="ticketTab">
                <v-tab-item value="chatNotes">
                  <ChatNote
                    :relatedId="visitId"
                    :relatedType="'visit-notes'"
                    create-url="visit-follow-up-notes"
                    get-url="visit-follow-up-notes"
                    :documentTypes="30"
                    :detail="detail"
                    :visit="visit"
                  ></ChatNote>
                </v-tab-item>
                <v-tab-item value="other">
                  <v-container fluid>
                    <v-card
                      flat
                      class="custom-grey-border remove-border-radius mb-4"
                    >
                      <v-card-title class="headline grey lighten-4">
                        <span
                          class="font-weight-700 custom-headline color-custom-blue font-size-16"
                          >Description</span
                        >
                      </v-card-title>
                      <v-card-text class="p-6 font-size-16">
                        <read-more
                          class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                          more-str="read more"
                          :text="
                            detail.description || '<em>No Description</em>'
                          "
                          link="#"
                          less-str="read less"
                          :max-chars="200"
                        >
                        </read-more>
                      </v-card-text>
                    </v-card>
                    <v-card
                      flat
                      class="custom-grey-border remove-border-radius mb-4"
                    >
                      <v-card-title class="headline grey lighten-4">
                        <span
                          class="font-weight-700 custom-headline color-custom-blue font-size-16"
                          >TERMS &amp; CONDITIONS</span
                        >
                      </v-card-title>
                      <v-card-text class="p-6 font-size-16">
                        <read-more
                          class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                          more-str="read more"
                          :text="
                            detail.term_conditions ||
                            '<em>No Terms &amp; Conditions</em>'
                          "
                          link="#"
                          less-str="read less"
                          :max-chars="200"
                        >
                        </read-more>
                      </v-card-text>
                    </v-card>
                    <v-card
                      flat
                      class="custom-grey-border remove-border-radius mb-4"
                    >
                      <v-card-title class="headline grey lighten-4">
                        <span
                          class="font-weight-700 custom-headline color-custom-blue font-size-16"
                          >Notes</span
                        >
                      </v-card-title>
                      <v-card-text class="p-6 font-size-16">
                        <v-layout class="mb-4">
                          <v-flex md6 class="custom-border-right">
                            <v-card-title class="headline grey lighten-4">
                              <span
                                class="font-weight-700 custom-headline color-custom-blue font-size-16"
                                >Client Contract Notes</span
                              >
                            </v-card-title>
                            <read-more
                              class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                              more-str="read more"
                              :text="
                                detail.client_remark ||
                                '<em>no Client Contract Notes</em>'
                              "
                              link="#"
                              less-str="read less"
                              :max-chars="200"
                            >
                            </read-more>
                          </v-flex>
                          <v-flex md6>
                            <v-card-title class="headline grey lighten-4">
                              <span
                                class="font-weight-700 custom-headline color-custom-blue font-size-16"
                                >Internal Admin Notes</span
                              >
                            </v-card-title>
                            <read-more
                              class="mx-4 custom-read-more font-weight-600 font-size-18 pb-1 custom-gray-color"
                              more-str="read more"
                              :text="
                                detail.admin_remark ||
                                '<em>No Internal Admin Notes</em>'
                              "
                              link="#"
                              less-str="read less"
                              :max-chars="200"
                            >
                            </read-more>
                          </v-flex>
                        </v-layout>
                        <v-layout
                          v-if="detail.delivery_documents.length"
                          class="custom-border-top"
                        >
                          <v-flex md12>
                            <FileTemplate
                              :attachments="detail.delivery_documents"
                            ></FileTemplate>
                          </v-flex>
                        </v-layout>
                      </v-card-text>
                    </v-card>
                  </v-container>
                </v-tab-item>
                <v-tab-item value="line-item">
                  <DeliveryLineItem
                    isDelivery
                    show-detail
                    :db-line-items="dbLineItems"
                    :show-price="visit.show_price"
                    :db-equipments="dbEquipments"
                    :discount-value="detail.discount_value"
                    :discount-value-type="detail.discount_value_type"
                    :apply-tax="detail.tax_applied"
                    :adjustment-value="detail.adjustment"
                    :tax-value="detail.tax_value"
                    v-on:load:content="getVisit()"
                    :isDelivered="isDelivere"
                  ></DeliveryLineItem>
                </v-tab-item>
                <v-tab-item value="pending">
                  <PendingLineItem
                    :isDelivered="isDelivere"
                    :parent-detail="visit"
                    :ticket-id="ticketId"
                    :is-receive="isReceive"
                    :is-failed="isFailed"
                    :type-delivery="type_delivery"
                    v-on:updatedata="refressData"
                    v-on:canceldata="cancelData"
                  ></PendingLineItem>
                </v-tab-item>
                <v-tab-item value="received">
                  <ReceivedLineItem
                    :parent-detail="visit"
                    :ticket-id="ticketId"
                  ></ReceivedLineItem>
                </v-tab-item>
                <v-tab-item value="deliveries">
                  <DeliveriesTab
                    :parent-detail="visit"
                    :ticket-id="ticketId"
                  ></DeliveriesTab>
                </v-tab-item>
                <v-tab-item value="timestamp">
                  <VisitTimestamp
                    :detail="{ id: ticketId }"
                    :visit-id="visitId"
                    v-on:delete:engineer="getVisit()"
                  >
                  </VisitTimestamp>
                </v-tab-item>

                <v-tab-item value="followup">
                  <VisitfollowupDetail
                    :detail="detail"
                    :visit="visit"
                    :page-loading="visitLoading"
                  ></VisitfollowupDetail>
                </v-tab-item>
                <v-tab-item value="available-tickets">
                  <VisitListingTemplate
                    :customer-id="detail.customer.id"
                    detailType="tickets"
                    visitType="all"
                  >
                  </VisitListingTemplate>
                </v-tab-item>
                <v-tab-item value="service-form">
                  <ServiceFormDetail
                    :detail="detail"
                    :visit="visit"
                    :accept_term="detail.accept_term"
                    :customer="detail.customer"
                    :property="detail.property"
                    :billing="detail.billing"
                    :contact-person="detail.property_person"
                    :page-loading="visitLoading"
                    is-visit-detail
                  >
                  </ServiceFormDetail>
                </v-tab-item>
                <v-tab-item value="closer">
                  <TicketCloserDetail
                    is-visit-detail
                    :visit-detail="visit"
                    :detail="detail"
                    :customer="detail.customer"
                    :property="detail.property"
                    :billing="detail.billing"
                    :contact-person="detail.property_person"
                    :page-loading="visitLoading"
                  ></TicketCloserDetail>
                </v-tab-item>
                <v-tab-item value="notes">
                  <VisitNotesDetail
                    v-if="false"
                    :detail="detail"
                    :visit="visit"
                    :notes="visit.notes"
                    :page-loading="visitLoading"
                  >
                  </VisitNotesDetail>
                  <ChatNote
                    :relatedId="visitId"
                    :relatedType="'delivery-notes'"
                    :documentTypes="125"
                    create-url="delivery-notes"
                    get-url="delivery-notes"
                    :detail="detail"
                    :visit="visit"
                  ></ChatNote>
                </v-tab-item>
                <!--  <v-tab-item value="invoice">
                  <InternalInvoice
                    type="visit"
                    :type-id="visitId"
                  ></InternalInvoice>
                </v-tab-item>
                <v-tab-item value="quotation">
                  <InternalQuotation
                    type="visit"
                    :type-id="visitId"
                  ></InternalQuotation>
                </v-tab-item> -->
                <v-tab-item
                  v-if="getPermission('history:view')"
                  :value="'history'"
                >
                  <InternalHistoryDetail
                    type="visit"
                    :type_id="visit.id"
                  ></InternalHistoryDetail>
                </v-tab-item>
                <v-tab-item value="team" v-if="false">
                  <VisitTeamDetail
                    :detail="{ id: ticketId }"
                    :visit-id="visitId"
                    v-on:delete:engineer="getVisit()"
                  >
                  </VisitTeamDetail>
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-container>
      </perfect-scrollbar>
      <Dialog :common-dialog="startTimerDialog" v-if="false">
        <template v-slot:title>Confirmation</template>
        <template v-slot:body>
          <v-form
            ref="timerStartForm"
            v-model.trim="timerFormValid"
            lazy-validation
            v-on:submit.stop.prevent="startTimer"
          >
            <v-container fluid class="py-0">
              <v-row>
                <v-col md="12" class="pt-0">
                  <p class="font-weight-600 font-size-18 color-custom-blue">
                    Are you sure, you want to start timer for visit#
                    {{ visit.barcode }} ?
                  </p>
                </v-col>
                <v-col md="12" class="d-flex align-center">
                  <label class="font-weight-600 font-size-16 m-0 mr-4"
                    >Service Form</label
                  >
                  <v-autocomplete
                    v-model.trim="timerServiceForm"
                    :items="serviceFormList"
                    hide-details
                    dense
                    filled
                    label="Select Service Form"
                    item-color="cyan"
                    color="cyan"
                    solo
                    flat
                    multiple
                    :disabled="timerFormLoading"
                    item-text="label"
                    item-value="value"
                    :rules="[
                      validateRules.required(timerServiceForm, 'Service Form'),
                    ]"
                  >
                    <template v-slot:append-outer>
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="mx-2" v-bind="attrs" v-on="on"
                            >mdi-progress-question</v-icon
                          >
                        </template>
                        <span
                          >Select service form which you<br />want to assign for
                          this visit</span
                        >
                      </v-tooltip>
                    </template>
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title
                          v-html="'Nothing Found.'"
                        ></v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="timerFormLoading || visitLoading"
            :loading="timerFormLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="startTimerWithLater"
          >
            Assign Later
          </v-btn>
          <v-btn
            :disabled="timerFormLoading || !timerFormValid || visitLoading"
            :loading="timerFormLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="startTimer"
          >
            Yes
          </v-btn>
          <v-btn
            :disabled="timerFormLoading || visitLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="startTimerDialog = false"
          >
            No
          </v-btn>
        </template>
      </Dialog>

      <Dialog :common-dialog="HoldandStayandCheckoutDialog">
        <template v-slot:title>Confirmation</template>
        <template v-slot:body>
          <v-form
            ref="timerStartForm"
            v-model.trim="timerFormValid"
            lazy-validation
            v-on:submit.stop.prevent="startTimer"
          >
            <v-container fluid class="py-0">
              <v-row>
                <v-col md="12" class="pt-0">
                  <p class="font-weight-600 font-size-18 color-custom-blue"></p>
                </v-col>

                <v-textarea
                  v-model.trim="data.remark"
                  auto-grow
                  dense
                  filled
                  :disabled="pageLoading"
                  :loading="pageLoading"
                  color="cyan"
                  label="Remarks"
                  solo
                  flat
                  :rules="[
                    validateRules.required(data.remark, 'Remarks'),
                    validateRules.minLength(data.remark, 'Remarks', 1),
                    validateRules.maxLength(data.remark, 'Remarks', 1024),
                  ]"
                  row-height="25"
                ></v-textarea>

                <!--  <v-col md="12" class="d-flex align-center">
                  <label class="font-weight-600 font-size-16 m-0 mr-4"
                    >Service Form</label
                  >
                  <v-autocomplete
                    v-model.trim="timerServiceForm"
                    :items="serviceFormList"
                    hide-details
                    dense
                    filled
                    label="Select Service Form"
                    item-color="cyan"
                    color="cyan"
                    solo
                    flat
                    multiple
                    :disabled="timerFormLoading"
                    item-text="label"
                    item-value="value"
                    :rules="[
                      validateRules.required(timerServiceForm, 'Service Form'),
                    ]"
                  >
                    <template v-slot:append-outer>
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="mx-2" v-bind="attrs" v-on="on"
                            >mdi-progress-question</v-icon
                          >
                        </template>
                        <span
                          >Select service form which you<br />want to assign for
                          this visit</span
                        >
                      </v-tooltip>
                    </template>
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title
                          v-html="'Nothing Found.'"
                        ></v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col> -->
              </v-row>
            </v-container>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="timerFormLoading || !timerFormValid || visitLoading"
            :loading="timerFormLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="HoldandStayDialog = true"
          >
            HOLD & STAY
          </v-btn>
          <v-btn
            :disabled="timerFormLoading || !timerFormValid || visitLoading"
            :loading="timerFormLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="HoldandCheckoutDialog = true"
          >
            HOLD & CHECKOUT
          </v-btn>
          <v-btn
            :disabled="timerFormLoading || visitLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="HoldandStayandCheckoutDialog = false"
          >
            No
          </v-btn>
        </template>
      </Dialog>

      <Dialog :common-dialog="HoldandStayDialog">
        <template v-slot:title>Confirmation</template>
        <template v-slot:body>
          <v-form
            ref="timerStartForm"
            v-model.trim="timerFormValid"
            lazy-validation
            v-on:submit.stop.prevent="startTimer"
          >
            <v-container fluid class="py-0">
              <v-row>
                <v-col md="12" class="pt-0">
                  <p class="font-weight-600 font-size-18 color-custom-blue">
                    {{ visit.barcode }} will be on hold and all engineers stay
                    assigned to the job
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="timerFormLoading || visitLoading"
            :loading="timerFormLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="updateOrCreateHold('stop_timer', 'hold')"
          >
            OK
          </v-btn>
          <v-btn
            :disabled="timerFormLoading || visitLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="HoldandStayDialog = false"
          >
            No
          </v-btn>
        </template>
      </Dialog>

      <Dialog :common-dialog="HoldandCheckoutDialog">
        <template v-slot:title>Confirmation</template>
        <template v-slot:body>
          <v-form
            ref="timerStartForm"
            v-model.trim="timerFormValid"
            lazy-validation
            v-on:submit.stop.prevent="startTimer"
          >
            <v-container fluid class="py-0">
              <v-row>
                <v-col md="12" class="pt-0">
                  <p class="font-weight-600 font-size-18 color-custom-blue">
                    {{ visit.barcode }} will be on hold and all engineer will be
                    unassigned
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="timerFormLoading || visitLoading"
            :loading="timerFormLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="
              updateOrCreateHoldandCheckout('stop_timer', 'checkouthold')
            "
          >
            OK
          </v-btn>
          <v-btn
            :disabled="timerFormLoading || visitLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="HoldandCheckoutDialog = false"
          >
            No
          </v-btn>
        </template>
      </Dialog>

      <Dialog :common-dialog="assignServiceFormDialog">
        <template v-slot:title>Confirmation</template>
        <template v-slot:body>
          <v-form
            ref="assignServiceForm"
            v-model.trim="assignFormValid"
            lazy-validation
            v-on:submit.stop.prevent="assignServiceForm"
          >
            <v-container fluid class="py-0">
              <v-row>
                <v-col md="12" class="d-flex align-center">
                  <label class="font-weight-600 font-size-16 m-0 mr-4"
                    >Service Form</label
                  >
                  <v-autocomplete
                    v-model.trim="timerServiceForm"
                    :items="serviceFormList"
                    hide-details
                    dense
                    filled
                    label="Select Service Form"
                    item-color="cyan"
                    color="cyan"
                    solo
                    flat
                    multiple
                    :disabled="assignFormLoading"
                    item-text="label"
                    item-value="value"
                    :rules="[
                      validateRules.required(timerServiceForm, 'Service Form'),
                    ]"
                  >
                    <template v-slot:append-outer>
                      <v-tooltip top content-class="custom-top-tooltip">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="mx-2" v-bind="attrs" v-on="on"
                            >mdi-progress-question</v-icon
                          >
                        </template>
                        <span
                          >Select service form which you<br />want to assign for
                          this visit</span
                        >
                      </v-tooltip>
                    </template>
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-title
                          v-html="'Nothing Found.'"
                        ></v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="assignFormLoading || !assignFormValid || visitLoading"
            :loading="assignFormLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="assignServiceForm"
          >
            Assign
          </v-btn>
          <v-btn
            :disabled="assignFormLoading || visitLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="assignServiceFormDialog = false"
          >
            Cancel
          </v-btn>
        </template>
      </Dialog>
      <Dialog :common-dialog="resumeTimerDialog">
        <template v-slot:title>Confirmation</template>
        <template v-slot:body>
          <v-container fluid class="py-0">
            <v-row>
              <v-col md="12" class="py-0">
                <p class="font-weight-600 font-size-18 color-custom-blue my-0">
                  Are you sure, you want to resume timer for visit#
                  {{ visit.barcode }} ?
                </p>
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="timerLoading || timerFormLoading || visitLoading"
            :loading="timerLoading || timerFormLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="resumeTimer()"
          >
            Yes
          </v-btn>
          <v-btn
            :disabled="timerLoading || timerFormLoading || visitLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="resumeTimerDialog = false"
          >
            No
          </v-btn>
        </template>
      </Dialog>
      <Dialog :common-dialog="remark_dialog">
        <template v-slot:title> Confirmation </template>
        <template v-slot:body>
          <v-form ref="statusForm" v-model.trim="formValid" lazy-validation>
            <v-container class="px-10" fluid>
              <p class="font-weight-600 font-size-19">
                Are you sure, you want to close ?
              </p>
              <v-layout class="my-4">
                <v-flex>
                  <label class="font-weight-500 font-size-16 required"
                    >Remark</label
                  >
                  <v-textarea
                    auto-grow
                    dense
                    filled
                    color="cyan"
                    label="Remark"
                    solo
                    flat
                    row-height="25"
                  ></v-textarea>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="confirm_loading"
            v-on:click="remark_dialog = false"
            class="mx-2 custom-grey-border custom-bold-button"
          >
            No! Close
          </v-btn>
          <v-btn
            :disabled="!formValid || confirm_loading"
            :loading="confirm_loading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
          >
            Yes! Continue
          </v-btn>
        </template>
      </Dialog>
      <!-- <Dialog :common-dialog="delivery_item_dialog">
        <template v-slot:title> Save As Delivery Item </template>
        <template v-slot:body>
          <v-form ref="statusForm" v-model.trim="formValid" lazy-validation>
            <v-container class="delivery">
                <v-row class="mb-30">
                  <v-col md="4">
                    <label class="font-weight-500 font-size-16"
                      >DO Number</label
                    >
                  </v-col> 
                  <v-col md="8">
                    <v-text-field
                        id="DO-Number"
                        dense
                        filled
                        label="DO Number"
                        solo
                        flat
                        color="cyan"
                        class="required-field"
                      ></v-text-field>
                  </v-col> 
               </v-row> 
               <v-row class="mb-30">
                  <v-col md="4">
                    <label class="font-weight-500 font-size-16"
                      >Drivers</label
                    >
                  </v-col> 
                  <v-col md="8">
                    <v-text-field
                        id="DO-Number"
                        dense
                        filled
                        label="Drivers"
                        solo
                        flat
                        color="cyan"
                        class="required-field"
                      ></v-text-field>

                  </v-col> 
                </v-row> 
              <v-row class="mb-30">
                <v-col md="4">
                  <label class="font-weight-500 font-size-16"
                    >Remark</label
                  >
                </v-col> 
                <v-col md="8">
                  <v-textarea
                    auto-grow
                    dense
                    filled
                    color="cyan"
                    label="Remark"
                    solo
                    flat
                    row-height="20"
                  ></v-textarea>
                </v-col> 
              </v-row>
            </v-container>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="confirm_loading"
            v-on:click="delivery_item_dialog = false"
            class="mx-2 custom-grey-border custom-bold-button"
          >
            No! Close
          </v-btn>
          <v-btn
            :disabled="!formValid || confirm_loading"
            :loading="confirm_loading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
          >
            Yes! Continue
          </v-btn>
        </template>
      </Dialog> -->
      <Dialog :common-dialog="appointmentUpdateDialog">
        <template v-slot:title>Reschedule Appointment</template>
        <template v-slot:body>
          <v-container fluid class="py-0" style="min-height: 27vh">
            <v-row>
              <!-- <v-col md="4" class="py-1 font-weight-600"> Date </v-col>
              <v-col md="4" class="py-1 font-weight-600"> Start Time </v-col>
              <v-col md="4" class="py-1 font-weight-600"> End Time </v-col> -->
              <v-col md="6" class="py-1">
                <div class="py-1 font-weight-600">Start Date</div>
                <Datepicker
                  :defaultDate="updateStartDate"
                  solo
                  v-on:update:date-picker="updateAppointmentDate"
                >
                </Datepicker>
              </v-col>
              <v-col md="6" class="py-1">
                <div class="py-1 font-weight-600">Start Time</div>
                <!-- <Timepicker
                  :defaultTime="updateStartTime"
                  solo
                  v-on:update:time-picker="updateAppointmentStartTime"
                ></Timepicker> -->
                <div class="time-picker-w-100 w-100">
                  <vue-timepicker
                    :minute-interval="15"
                    placeholder="Start Time"
                    input-width="17em"
                    :format="yourFormat"
                    v-model="starTimereschdule"
                    solo
                  >
                  </vue-timepicker>
                </div>
              </v-col>
              <!-- <v-col md="6" class="py-1">
                <div class="py-1 font-weight-600">End Date</div>
                <Datepicker
                  :defaultDate="updateEndDate"
                  solo
                  v-on:update:date-picker="updateAppointmentEndDate"
                >
                </Datepicker>
              </v-col> -->
              <v-col md="6" class="py-1">
                <div class="py-1 font-weight-600">End Time</div>
                <!-- <Timepicker
                  :defaultTime="updateEndTime"
                  solo
                  v-on:update:time-picker="updateAppointmentEndTime"
                ></Timepicker> -->
                <div class="time-picker-w-100 w-100">
                  <vue-timepicker
                    :minute-interval="15"
                    placeholder="End Time"
                    input-width="17em"
                    :format="yourFormat"
                    v-model="endTimereschdule"
                    solo
                  >
                  </vue-timepicker>
                </div>
              </v-col>

              <v-col md="12" class="py-2">
                <em
                  >All engineers will be removed from visit#
                  {{ visit.barcode }}</em
                >
              </v-col>
            </v-row>
          </v-container>
        </template>
        <template v-slot:action>
          <v-btn
            :disabled="apptFormLoading || visitLoading"
            :loading="apptFormLoading"
            class="mx-2 custom-grey-border custom-bold-button white--text"
            color="cyan"
            v-on:click="updateAppointment()"
          >
            Save
          </v-btn>
          <v-btn
            :disabled="apptFormLoading || visitLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="appointmentUpdateDialog = false"
          >
            Close
          </v-btn>
        </template>
      </Dialog>

      <Dialog :common-dialog="confirm_dialog">
        <template v-slot:title> Confirmation </template>
        <template v-slot:body>
          <v-form ref="statusForm" v-model.trim="formValid" lazy-validation>
            <v-container class="px-10" fluid>
              <p class="font-weight-600 font-size-19">
                Are you sure, you want to
                <span class="font-weight-700">Reject</span>?
              </p>
              <!--    <p v-else class="font-weight-600 font-size-19">
                You are cancel a contract job, do you want to reverse consume
                service back to contract?
              </p> -->
              <label for="cancel-reason" class="font-weight-600 font-size-18"
                >Reason</label
              >
              <v-autocomplete
                dense
                color="cyan"
                filled
                id="payment-mode"
                :items="resonList"
                :disabled="pageLoading"
                :loading="pageLoading"
                v-model.trim="cancel_reason_type"
                label="Reason"
                solo
                flat
                item-color="cyan"
                item-text="text"
                item-value="value"
                hide-details
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="'No Reason Found.'"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>
              <label for="cancel-reason" class="font-weight-600 font-size-18"
                >Remark</label
              >
              <v-textarea
                id="cancel-reason"
                auto-grow
                dense
                filled
                color="cyan"
                placeholder="Enter Remark..."
                solo
                flat
                v-model="cancel_reason"
                row-height="25"
              />
            </v-container>
          </v-form>
        </template>
        <template v-slot:action>
          <template>
            <v-btn
              v-on:click="updateStatus('mark_as_rejected')"
              :disabled="!formValid || statusLoading"
              :loading="statusLoading"
              class="mx-2 custom-bold-button white--text"
              color="cyan"
            >
              Yes! Continue
            </v-btn>
          </template>
          <v-btn
            :disabled="statusLoading"
            v-on:click="confirm_dialog = false"
            class="mx-2 custom-grey-border custom-bold-button"
          >
            Cancel
          </v-btn>
        </template>
      </Dialog>
      <Dialog :common-dialog="accept_dialog">
        <template v-slot:title> Confirmation </template>
        <template v-slot:body>
          <v-form ref="statusForm" v-model.trim="formValid" lazy-validation>
            <v-container class="px-10" fluid style="text-align: center">
              <p class="font-weight-600 font-size-19">
                Do you want to proceed
                <!-- <span class="font-weight-700" style="color:red">Accept</span>   --><span
                  class="font-weight-700"
                >
                  #{{ visit.barcode }}</span
                >?
              </p>
            </v-container>
          </v-form>
        </template>
        <template v-slot:action>
          <template>
            <v-btn
              :disabled="!formValid || statusLoading"
              :loading="statusLoading"
              class="mx-2 custom-bold-button white--text"
              v-on:click="updateStatus('mark_as_accepted')"
              color="cyan"
            >
              Yes
            </v-btn>
          </template>
          <v-btn
            :disabled="statusLoading"
            v-on:click="accept_dialog = false"
            class="mx-2 custom-grey-border custom-bold-button"
          >
            No
          </v-btn>
        </template>
      </Dialog>

      <!--  <template v-if="team_dialog">
        <SelectTeam :team-dialog="team_dialog" v-on:resetAll="team_dialog = false" :visit-data="visit"
          :ticket-data="detail">
        </SelectTeam>
      </template> -->
      <CreateCloser
        v-if="runningTimer.id"
        is-visit-detail
        :ticketId="ticketId"
        :accept_term="detail.accept_term"
        :ticket-id="ticketId"
        :visit-id="visitId"
        :timesheet-id="runningTimer.id"
        :timesheet="runningTimer.id"
        :visit-detail="visit"
        :visit-payment="paymentmodes"
        :admin-email="company_email"
        :customer-mail="customer_email"
        :user-data="userArr.user_email"
        :user-detail="userArr"
        :closer-dialog="closerDialog"
        :detail="detail"
        v-on:create:ticket-closer="createdTimeCloser"
        v-on:close-dialog="closerDialog = false"
        :total-charge="runningAmount"
      ></CreateCloser>
      <SendMail
        :mail-dialog="mail_dialog"
        title="Send as Email"
        type="visit"
        v-on:close="mail_dialog = false"
        :type-id="visitId"
        v-on:success="getVisit()"
      ></SendMail>
      <ShareServiceReport
        :mail-dialog="share_dialog"
        title="Share Service Report"
        v-on:close="share_dialog = false"
        :visit-id="visitId"
        v-on:success="getVisit()"
        v-on:open="share_dialog = true"
      ></ShareServiceReport>
      <ReassignSchedule
        :reassign-schedule-dialog="reassign_dialog"
        v-on:close="reassign_dialog = false"
        :visit-id="visitId"
        :ticket-id="ticketId"
        v-on:success="getVisit()"
      ></ReassignSchedule>
      <FailedDelivery
        :failed-dialog="failed_dialog"
        v-on:close="failed_dialog = false"
        :visit-id="visitId"
        :ticket-id="ticketId"
        v-on:success="getVisit()"
      ></FailedDelivery>
    </template>
  </DetailTemplate>
</template>
<script>
import moment from "moment-timezone";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CommonMixin from "@/core/plugins/common-mixin";
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import ChatNote from "@/view/pages/partials/ChatNote";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
/* import DeliveryLineItem from "@/view/pages/partials/Delivery-Line-Item-Detail"; */
import DeliveryLineItem from "@/view/pages/partials/Line-Item-Detail-New";
import PendingLineItem from "@/view/pages/partials/Pending-Line-Item";
import ReceivedLineItem from "@/view/pages/partials/Do-Line-Item";
import DeliveriesTab from "@/view/pages/partials/Deliveries-Tab";
import ValidationMixin from "@/core/plugins/validation-mixin";
import VisitTeamDetail from "@/view/pages/delivery-job/partials/Visit-Team-Detail.vue";
import VisitfollowupDetail from "@/view/pages/job/partials/Visit-follow-up-Detail.vue";
import VisitListingTemplate from "@/view/pages/visit/Visit-Listing-Template";
import VisitTimestamp from "@/view/pages/job/partials/Visit-Timestamp.vue";
import ServiceFormDetail from "@/view/pages/job/partials/Service-Form-Detail.vue";
import TicketCloserDetail from "@/view/pages/job/partials/Ticket-Closer-Detail.vue";
import VisitNotesDetail from "@/view/pages/job/partials/Visit-Notes-Detail.vue";
import SendMail from "@/view/pages/partials/Send-Mail";
import ShareServiceReport from "@/view/pages/partials/Share-Service-Report-Mail";
import ReassignSchedule from "@/view/pages/partials/Reassign-Schedule.vue";
import FailedDelivery from "@/view/pages/partials/Failed-Delivery.vue";
//import InternalInvoice from "@/view/pages/partials/Internal-Invoice";
import Datepicker from "@/view/pages/partials/Datepicker.vue";
//import Timepicker from "@/view/pages/partials/Timepicker.vue";
import Dialog from "@/view/pages/partials/Dialog";
//import SelectTeam from "@/view/pages/partials/Select-Team.vue";
//import InternalQuotation from "@/view/pages/partials/Internal-Quotation";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
//import {  CheckinCheckoutcata,} from "@/core/lib/checkin.lib";
import {
  GET,
  QUERY,
  PATCH,
  POST,
  PUT,
} from "@/core/services/store/request.module";
import Priority from "@/view/pages/partials/Priority.vue";
import CreateCloser from "@/view/pages/job/partials/Create-Ticket-Closer.vue";
import JwtService from "@/core/services/jwt.service";
import JobRequestMixin from "@/core/lib/job/api.request.job.mixin";
import { ADD_CHECKOUT_VISIT } from "@/core/services/store/common.module";
import {
  ErrorEventBus,
  InitializeError,
  SuccessEventBus,
} from "@/core/lib/message.lib";
import {
  toSafeInteger,
  filter,
  head,
  last,
  isArray,
  isEmpty,
  concat,
  toNumber,
  find,
  forEach,
  toString,
  padStart,
} from "lodash";

import { downloadEvent } from "@/core/lib/ics.lib";
import { ServiceFormEventBus } from "@/core/lib/service.form.lib";
import { DeliveryEventBus } from "@/core/lib/delivery.lib.js";
import { currentUser } from "@/core/services/jwt.service";
import Barcode from "@/view/pages/partials/Barcode.vue";
import ObjectPath from "object-path";
//import CreateTicketCloserMixin from "@/core/lib/ticket-closer/create.ticket.closer.mixin";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [
    CommonMixin,
    ValidationMixin,
    JobRequestMixin,
    // CreateTicketCloserMixin,
  ],
  data() {
    return {
      visitId: 0,
      yourFormat: "hh:mm A",
      ticketId: 0,
      share_dialog: false,
      cancel_reason: null,
      accept_dialog: false,
      pageLoading: false,
      pageLoaded: false,
      statusLoading: false,
      confirm_loading: false,
      mail_dialog: false,
      visitLoading: false,
      cancel_reason_type: null,
      serviceFormLoading: false,
      failed_dialog: false,
      delivery_item_dialog: false,
      appointmentUpdateDialog: false,
      endTimereschdule: null,
      starTimereschdule: null,
      timerFormLoading: false,
      serviceFormList: [],
      resonList: [
        {
          value: "technical-issue",
          text: "Technical Issue",
        },
        /* {
          value: 'technical-issue',
          text: "Technical Issue",
        },
        {
          value: 'technical-issue',
          text: "Technical Issue",
        }, */
      ],
      startTimerDialog: false,
      isReceive: false,
      reassign_dialog: false,
      follow_up_route: null,
      HoldandStayandCheckoutDialog: false,
      HoldandStayDialog: false,
      HoldandCheckoutDialog: false,
      timerLoading: false,
      team_dialog: false,
      remark_dialog: false,
      checkout: {},
      runningAmount: {
        service_form: null,
        ticket: null,
        visit: null,
        total_charge: 0,
      },
      data: {
        remark: null,
      },
      runningTimer: {
        barcode: null,
        closer: null,
        date: null,
        duration: null,
        end_at: null,
        end_date: null,
        id: null,
        is_service_form_assigned: false,
        is_service_form_filled: false,
        service_form: null,
        start_at: null,
        status: null,
        status_text: null,
        ticket: null,
        visit: null,
        total_charge: 0,
      },
      timerDuration: {
        hour: "00",
        minute: "00",
        second: "00",
      },
      isTimmerRunning: false,
      type_delivery: null,
      resumeTimerDialog: false,
      teamEngineers: [],
      closerDialog: false,
      assignFormLoading: false,
      assignServiceFormDialog: false,
      timerFormValid: true,
      assignFormValid: true,
      timeInterval: null,
      durationInterval: null,
      timeIntervalLimit: 10000,
      isDelivere: false,
      updateAppointmentFormData: {
        date: null,
        start_time: null,
        end_date: null,
        end_time: null,
      },
      apptFormLoading: false,
      apptEngLoading: false,
      engineer_menu: false,
      ticketTab: "chatNotes",
      timerServiceForm: [],
      serviceformdata: [],
      moreActions: [],
      detail: {
        term_conditions: null,
      },
      visit: {},
      paymentmodes: {},
      company_email: {},
      dbLineItems: [],
      isFailed: false,
      dbEquipments: [],
      engineerList: [],
      assigned_team: [],
      closerdata: [],
      confirm_dialog: false,
    };
  },
  methods: {
    /*   getNewId(count){
       let data = this.visit.barcode.split("-")
        let countData =  Number(data[1]) +  Number(count) 
        return  data[0]+ '-' + countData;
      }, */

    reassignScheduleDialog() {
      this.reassign_dialog = true;
    },
    failedDialog(type) {
      this.type_delivery = type;
      this.ticketTab = "pending";
      this.isFailed = true;
    },
    acceptItem() {
      this.accept_dialog = true;
    },
    saveDeliveryItem(type) {
      /*   this.isDelivere = false; */
      this.type_delivery = type;
      this.isFailed = true;
      /*   this.isReceive = true; */
    },
    checkStartTimeButton() {
      const user = currentUser();
      // console.log(user, "user");
      if (this.visit.engineers) {
        let startTimerFormData = find(
          this.visit.engineers,
          (row) => row.user == user.id
        );
        // console.log(this.visit.engineers, "this.visit.engineers");

        //  console.log(startTimerFormData, "startTimerFormData")
        if (startTimerFormData) {
          return true;
        }
      }
      return false;
    },
    getFollowColor(data) {
      if (data == 1) {
        return "green";
      } else {
        return "red";
      }
    },
    refressData() {
      const _this = this;
      this.isFailed = false;
      setTimeout(function () {
        _this.getVisit();
        _this.ticketTab = "received";
        DeliveryEventBus.$emit("update:delivery", true);
      }, 500);
      this.isDelivere = false;
    },
    cancelData() {
      this.isFailed = false;
    },
    deliveredItem() {
      this.isDelivere = true;
      this.ticketTab = "pending";
    },
    VisitChecoutUpdate() {
      const _this = this;

      _this.formLoading = true;

      _this.$store
        .dispatch(PATCH, { url: "checkout/" + this.visitId })
        .then(() => {})
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    /* getClosers() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "job/" + this.ticketId + "/complate-closers",
        })
        .then((response) => {
          this.closerdata = response.data;
        });
    }, */
    getFormattedBilling(row) {
      let tempArr = [];
      if (row.unit_no) {
        tempArr.push(row.unit_no);
      }
      if (row.street_1) {
        tempArr.push(row.street_1);
      }
      if (row.street_2) {
        tempArr.push(row.street_2);
      }
      if (row.zip_code && row.zip_code != "000000") {
        tempArr.push(row.zip_code);
      }
      if (this.lodash.isEmpty(row.city) === false) {
        tempArr.push(row.city.name);
      }
      if (this.lodash.isEmpty(row.state) === false) {
        tempArr.push(row.state.name);
      }
      if (this.lodash.isEmpty(row.country) === false) {
        tempArr.push(row.country.name);
      }
      return tempArr.join(", ");
    },
    VisitCheckinUpdate() {
      const _this = this;

      _this.formLoading = true;

      _this.$store
        .dispatch(POST, { url: "checkin/" + this.visitId })
        .then((data) => {
       /*    _this.getActiveTimer(); */
          this.checkIn = data;
          ServiceFormEventBus.$emit("reload:timestam-refresh", true);
          // _this.getActiveTimer();
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    getLineItems(data) {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "line-item/new",
          data,
        })
        .then(({ data }) => {
          //   console.log(data, "data")
          if (data.length) {
            const result = [];

            for (let i = 0; i < data.length; i++) {
              result.push({
                id: data[i].id,
                group: data[i].group,
                group_primary: data[i].group_primary,
                product: data[i].product,
                product_id: data[i].product_id,
                delivery_types: data[i].delivery_types,
                delivered_quantity: data[i].delivered_quantity,
                balance_quantity: data[i].balance_quantity,
                delivered_status: data[i].delivered_status,
                serial_no: data[i].serial_no,
                eq_model: data[i].eq_model,
                location: data[i].location,
                to_equipment: data[i].to_equipment,
                product_type: data[i].product_type,
                project_price: data[i].project_price,
                has_warranty: data[i].has_warranty,
                warranty: data[i].warranty,
                description: data[i].description,
                rate: data[i].rate,
                quantity: data[i].quantity,
                uom: data[i].uom,
                total: data[i].total,
                visit_barcode: data[i].visit_barcode,
                is_optional: data[i].is_optional,
              });
            }

            _this.$nextTick(() => {
              _this.dbLineItems = filter(result, function (row) {
                return (
                  row.product_type == "goods" || row.product_type == "service"
                );
              });
              _this.dbEquipments = filter(result, {
                product_type: "equipment",
              });
            });
          }
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getDurationText() {
      const start_date = head(this.detail.duration);
      const last_date = last(this.detail.duration);
      return `${this.formatDate(start_date)} to ${this.formatDate(last_date)}`;
    },
    getServiceFormList() {
      if (this.serviceFormLoading) {
        return false;
      }
      this.serviceFormLoading = true;
      this.$store
        .dispatch(QUERY, {
          url: "job/service-forms",
          data: { customer: this.visit.customer },
        })
        .then(({ data }) => {
          this.serviceFormList = data;
          this.timerServiceForm = this.lodash.map(
            this.serviceFormList,
            (row) => row.value
          );

          this.startTimerDialog = true;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.serviceFormLoading = false;
        });
    },
    formatDateStartTime(row) {
      return moment(row).format("hh:mm A");
    },
    startTimer() {
      // alert(223);
      /* if (!this.$refs.timerStartForm.validate()) {
        return false;
      } */

      this.timerFormLoading = true;
      this.timerLoading = true;

      this.$store
        .dispatch(PATCH, {
          url:
            "job/" + this.ticketId + "/visit/" + this.visitId + "/timer/start",
          data: { service_form: this.timerServiceForm },
        })
        .then(() => {
          this.startTimerDialog = false;
          this.timerServiceForm = new Array();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerFormLoading = false;
          this.timerLoading = false;
          this.getVisit();
          this.checkTimerStatus();
          this.updateTimerDuration();
        });
    },

    updateOrCreateHold(action, type) {
      const _this = this;

      if (_this.recordingStarted === true && _this.recordingStopped === false) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please stop the audio recorder then try again.")
        );
        return false;
      }

      _this.formLoading = true;

      const signatures = [];
      const formData = {
        action,
        type,
        ticket: _this.ticketId,
        visit: _this.visitId,
        timesheet: _this.runningTimer.id,
        attachments: _this.data.attachments,
        signatures: signatures,
        audio: _this.audioDataURL,
        payment_collected: _this.collected_amount,
        payment_status: _this.collected_status,
        payment_mode: _this.payment_mode,
        details: _this.data.details,
        remark: _this.data.remark,

        tested_with_client: _this.data.tested_with_client,
      };
      _this.$store
        .dispatch(POST, {
          url: "job/" + _this.ticketId + "/visit/" + _this.visitId + "/hold",
          data: formData,
        })
        .then(() => {
          this.HoldandStayDialog = false;
          this.HoldandStayandCheckoutDialog = false;
          this.getVisit();
         /*  this.getActiveTimer(); */
          // _this.$emit("create:ticket-closer", true);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    updateOrCreateHoldandCheckout(action, type) {
      const _this = this;

      /*   if (!_this.$refs.ticketCloserForm.validate()) {
        return false;
      } */

      if (_this.recordingStarted === true && _this.recordingStopped === false) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please stop the audio recorder then try again.")
        );
        return false;
      }

      _this.formLoading = true;

      const signatures = [];
      const formData = {
        action,
        type,
        ticket: _this.ticketId,
        visit: _this.visitId,
        timesheet: _this.runningTimer.id,
        attachments: _this.data.attachments,
        signatures: signatures,
        audio: _this.audioDataURL,
        payment_collected: _this.collected_amount,
        payment_status: _this.collected_status,
        payment_mode: _this.payment_mode,
        details: _this.data.details,
        remark: _this.data.remark,

        tested_with_client: _this.data.tested_with_client,
      };
      _this.$store
        .dispatch(POST, {
          url:
            "job/" + _this.ticketId + "/visit/" + _this.visitId + "/checkout",
          data: formData,
        })
        .then(() => {
          this.HoldandCheckoutDialog = false;
          this.HoldandStayandCheckoutDialog = false;
          this.getVisit();
         /*  this.getActiveTimer(); */
          // _this.$emit("create:ticket-closer", true);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },

    startTimerWithLater() {
      if (this.isTimmerRunning) {
        this.closerDialog = true;
      } else {
        this.timerFormLoading = true;
        this.timerLoading = true;
        this.$store
          .dispatch(PATCH, {
            url:
              "job/" +
              this.ticketId +
              "/visit/" +
              this.visitId +
              "/timer/start",
          })
          .then(() => {
            this.startTimerDialog = false;
            this.timerServiceForm = new Array();
          })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.timerFormLoading = false;
            this.timerLoading = false;
            this.getVisit();
            this.checkTimerStatus();
            this.updateTimerDuration();
          });
      }
    },
    getActiveTimer() {
      if (this.visit.status == 2) {
        this.timerLoading = true;
        this.$store
          .dispatch(GET, {
            url: "visit/" + this.visitId + "/running/web",
          })
          .then(({ data }) => {
            this.runningTimer = new Object(data.result);
            this.checkout = new Object(data.checkOut);
            this.$store.dispatch(ADD_CHECKOUT_VISIT, this.checkout);

            //CheckinCheckoutcata.$emit("visit-newposts-code",this.checkout);
            // console.log(this.checkout, "this.checkout");
            this.isTimmerRunning = !!data;
            if (this.isTimmerRunning) {
              this.resumeTimerDialog = false;
            }
          })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.timerLoading = false;
            this.setTimerDuration();
          });
      }
    },
    closeFollowUp(data) {
      const _this = this;
      if (data.status == 2) {
        return false;
      }
      _this.$store
        .dispatch(PATCH, {
          url: "close-followup",
          data: {
            id: data.id,
          },
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.getVisit();
        });
    },
    /*  getAmount() {
      if (this.visit.status != 1) {
        this.timerLoading = true;
        this.$store
          .dispatch(GET, {
            url: "visit/" + this.visitId + "/running-amount",
          })
          .then(({ data }) => {
            console.log(data,"data");
            this.runningAmount = new Object(data);
          })
          .catch((error) => {
            console.log(error,"error");
            this.logError(error);
          })
          .finally(() => {
            this.timerLoading = false;
            this.setTimerDuration();
          });
      }
    },
 */
    /*   getActiveTimer() {
      
        this.timerLoading = true;
        this.$store
          .dispatch(GET, {
            url: "visit/" + this.visitId + "/running",
          })
          .then(({ data }) => {
          
            this.runningTimer = new Object(data);
            this.isTimmerRunning = !!data;
            if (this.isTimmerRunning) {
              this.resumeTimerDialog = false;
            }
          })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.timerLoading = false;
            this.setTimerDuration();
          });
      
    }, */

    /*  checkSignatureStatus(action) {
      if (action == "signature_link" || action == "send_as_email") {
        if (this.closerdata.accept_term == 1 || this.is_staff) {
          return true;
        }
        return false;
      }

      return true;
    }, */

    checkTimerStatus() {
      const _this = this;
      clearInterval(_this.timeInterval);
      _this.timeInterval = setInterval(
      /*   _this.getActiveTimer, */
        _this.timeIntervalLimit
      );
    },
    updateTimerDuration() {
      const _this = this;
      clearInterval(_this.durationInterval);
      _this.durationInterval = setInterval(_this.setTimerDuration, 1000);
    },
    setTimerDuration() {
      if (isEmpty(this.runningTimer) === false) {
        let startDate = toString(this.runningTimer.date);
        if (this.runningTimer.start_at) {
          startDate = toString(startDate + " " + this.runningTimer.start_at);
        }

        if (startDate && moment(startDate, ["YYYY-MM-DD HH:mm:ss"]).isValid()) {
          startDate = moment(startDate, ["YYYY-MM-DD HH:mm:ss"]);

          let duration = moment.duration(moment().diff(startDate));

          let hours = parseInt(duration.asHours());
          let minutes = parseInt(duration.asMinutes()) % 60;
          let seconds = parseInt(duration.asSeconds()) % 60;

          this.timerDuration = {
            hour: hours > 0 ? padStart(hours, 2, "0") : "00",
            minute: minutes > 0 ? padStart(minutes, 2, "0") : "00",
            second: seconds > 0 ? padStart(seconds, 2, "0") : "00",
          };
        }
      }
    },
    assignServiceFormDetail() {
      this.timerLoading = true;

      this.$store
        .dispatch(QUERY, {
          url: "job/service-forms",
          data: {
            timesheet: this.runningTimer.id,
            visit: this.visitId,
            status: this.visit.status,
          },
        })
        .then(({ data }) => {
          this.serviceFormList = data;
          this.assignServiceFormDialog = true;
          this.timerServiceForm = this.lodash.map(
            this.serviceFormList,
            (row) => row.value
          );
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });
    },
    assignServiceForm() {
      if (!this.$refs.assignServiceForm.validate()) {
        return false;
      }

      this.assignFormLoading = true;

      this.$store
        .dispatch(POST, {
          url: `job/${this.ticketId}/visit/${this.visitId}/service-form/${this.timerServiceForm}`,
          data: { timesheet: this.runningTimer.id || null },
        })
        .then(() => {
          this.timerServiceForm = new Array();
          ServiceFormEventBus.$emit("reload:service-form");
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.assignFormLoading = false;
          this.assignServiceFormDialog = false;
        /*   this.getActiveTimer(); */
        });
    },
    createdTimeCloser() {
      this.serviceFormLoading = false;
      this.timerFormLoading = false;
      this.serviceFormList = [];
      this.startTimerDialog = false;
      this.HoldandStayandCheckoutDialog = false;
      this.timerLoading = false;
      (this.userArr = null),
        (this.customer_email = null),
        (this.company_email = null),
        (this.runningTimer = {
          barcode: null,
          closer: null,
          date: null,
          duration: null,
          end_at: null,
          end_date: null,
          id: null,
          is_service_form_assigned: false,
          is_service_form_filled: false,
          service_form: null,
          start_at: null,
          status: null,
          status_text: null,
          ticket: null,
          visit: null,
        });
      this.timerDuration = {
        hour: "00",
        minute: "00",
        second: "00",
      };
      this.isTimmerRunning = false;
      this.resumeTimerDialog = false;
      this.closerDialog = false;
      this.assignFormLoading = false;
      this.assignServiceFormDialog = false;
      this.timerFormValid = true;
      this.assignFormValid = true;
      this.getVisit();
      clearInterval(this.timeInterval);
      clearInterval(this.durationInterval);
    },
    resumeTimer() {
      this.timerFormLoading = true;
      this.$store
        .dispatch(PATCH, { url: "visit/" + this.visitId + "/resume" })
        .then(() => {
          this.resumeTimerDialog = false;

          /* if (this.checkout && this.checkout.id == null) {
            this.VisitCheckinUpdate();
          } */
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerFormLoading = false;
          this.getVisit();
          this.checkTimerStatus();
          this.updateTimerDuration();
        });
    },
    updateAppointmentDate(param) {
      this.updateAppointmentFormData.date = param;
    },
    updateAppointmentEndDate(param) {
      this.updateAppointmentFormData.end_date = param;
    },
    updateAppointmentStartTime(param) {
      this.starTimereschdule = param;
    },
    updateAppointmentEndTime(param) {
      this.endTimereschdule = param;
    },
    updateAppointment() {
      this.apptFormLoading = true;
      /* console.log(this.starTimereschdule,"start time");
      console.log(this.starTimereschdule,"end time"); */
      this.$store
        .dispatch(PATCH, {
          url:
            "job/" + this.ticketId + "/visit/" + this.visitId + "/re-schedule",
          data: {
            date: this.updateAppointmentFormData.date
              ? moment(this.updateAppointmentFormData.date).format("YYYY-MM-DD")
              : moment().format("YYYY-MM-DD"),
            end_date: this.updateAppointmentFormData.end_date
              ? moment(this.updateAppointmentFormData.end_date).format(
                  "YYYY-MM-DD"
                )
              : moment().format("YYYY-MM-DD"),

            start_time: this.starTimereschdule
              ? moment(this.starTimereschdule, ["hh:mm a"]).format("HH:mm")
              : moment().endOf("day").format("HH:mm"),
            end_time: this.endTimereschdule
              ? moment(this.endTimereschdule, ["hh:mm a"]).format("HH:mm")
              : moment().endOf("day").format("HH:mm"),

            /* start_time: this.starTimereschdule.start_time
              ? moment(this.starTimereschdule.start_time, [
                "h:mm A",
                ]).format("HH:mm")
              : moment().endOf("day").format("HH:mm"),

               end_time: this.endTimereschdule.end_time
              ? moment(this.endTimereschdule.end_time, [
                "h:mm A",
                ]).format("HH:mm")
              : moment().endOf("day").format("HH:mm"),   */
          },
        })
        .then(() => {
          this.appointmentUpdateDialog = false;
          this.$router.replace(this.getDefaultRoute("delivery"));
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.apptFormLoading = false;
        });
    },
    /* getAvailableEngineers() {
      this.loadingEngineers = true;
      this.$store
        .dispatch(QUERY, {
          url: "job/available-team-engineers",
          data: {
            start_date: moment(this.visit.started_at).format("YYYY-MM-DD"),
            start_time: moment(this.visit.started_at).format("HH:mm"),
            end_date: moment(this.visit.finished_at).format("YYYY-MM-DD"),
            end_time: moment(this.visit.finished_at).format("HH:mm"),
            force: 0,
            all: 1,
            web: true,
          },
        })
        .then(({ data }) => {
          this.engineerList = data.engineers;
          this.engineer_menu = true;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.loadingEngineers = false;
        });
    }, */
    getAvailableEngineers() {
      this.loadingEngineers = true;
      this.$store
        .dispatch(QUERY, {
          url: "job/available-engineers",
          data: {
            start_date: moment(this.visit.started_at).format("YYYY-MM-DD"),
            start_time: moment(this.visit.started_at).format("HH:mm"),
            end_date: moment(this.visit.finished_at).format("YYYY-MM-DD"),
            end_time: moment(this.visit.finished_at).format("HH:mm"),
            force: 0,
            all: 1,
          },
        })
        .then(({ data }) => {
          this.engineerList = data.engineers;
          this.engineer_menu = true;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.loadingEngineers = false;
        });
    },

    routeToDuplicate() {
      const { billing, billing_person, customer, property, property_person } =
        this.detail;
      this.$router.push(
        this.getDefaultRoute("job.create", {
          query: {
            customer: customer ? customer.id : null,
            billing: billing ? billing.id : null,
            property: property ? property.id : null,
            property_person: property_person ? property_person.id : null,
            billing_person: billing_person ? billing_person.id : null,
            duplicate: this.visitId,
          },
        })
      );
    },
    updateEngineer() {
      if (!this.assigned_team.length) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please select an driver then try again.")
        );
        return false;
      }
      this.apptEngLoading = true;
      this.$store
        .dispatch(PUT, {
          url: "job/" + this.ticketId + "/visit/" + this.visitId + "/delivery",
          data: {
            engineer: this.assigned_team,
          },
        })
        .then(() => {
          this.engineerList = [];
          SuccessEventBus.$emit(
            "update:success",
            this.visit.barcode + " Re-assign successfully."
          );
          this.$nextTick(() => {
            this.getVisit();
          });
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.apptEngLoading = false;
        });
    },
    updateEngineerOld() {
      //   console.log(this.assigned_team,"this.assigned_team");
      /* if (!this.assigned_team.length) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Please select an engineer then try again.")
        );
        return false;
      } */

      let EngineerId = [];
      let teamData = find(
        this.engineerList,
        (row) => row.id == this.assigned_team
      );
      let teamId = teamData.id;
      // console.log(teamData.id,"teamData");
      if (teamData && teamData.team_data) {
        forEach(teamData.team_data, (row3) => {
          EngineerId.push(row3.staff_data.id);
        });
      }

      // console.log(EngineerId,"EngineerId");

      this.apptEngLoading = true;

      if (isArray(EngineerId) && EngineerId.length > 0) {
        this.$store
          .dispatch(PUT, {
            url:
              "job/" + this.ticketId + "/visit/" + this.visitId + "/engineer",
            data: {
              engineer: EngineerId,
              teamId: teamId,
            },
          })
          .then(() => {
            this.engineerList = [];
            SuccessEventBus.$emit(
              "update:success",
              this.visit.barcode + " Re-assign successfully."
            );
            this.$nextTick(() => {
              this.getVisit();
            });
          })
          .catch((error) => {
            this.logError(error);
          })
          .finally(() => {
            this.apptEngLoading = false;
          });
      }
    },
    updateMoreAction(type) {
      switch (type) {
        case "duplicate":
          this.routeToDuplicate();
          break;
        case "convert_quotation":
          this.routeToQuotation();
          break;
        case "convert_invoice":
          this.routeToInvoice();
          break;
        case "re_schedule_visit":
          this.appointmentUpdateDialog = true;
          break;
        case "mark_as_cancel":
          this.confirm_dialog = true;
          //this.updateStatus("mark_as_cancel");
          break;
        case "mark_as_rejected":
          this.confirm_dialog = true;
          /* this.updateStatus("mark_as_rejected"); */
          break;
        case "mark_as_delivered":
          this.updateStatus("mark_as_delivered");
          break;
        case "send_as_email":
          this.mail_dialog = true;
          break;
        case "signature_link":
          this.getSignatureLink();
          break;
        case "available_staff":
          this.getAvailableEngineers();
          break;
        case "download_schedule":
          this.downloadSchedule();
          break;
        case "download_pdf":
          this.downloadPDF();
          break;
        case "print":
          this.printPDF();
          break;
      }
    },
    getSignatureLink() {
      this.$store
        .dispatch(GET, {
          url: "visit/" + this.visitId + "/signature-link",
        })
        .then(({ data }) => {
          const { signature_link } = data;
          window.open(signature_link, "_blank");
        })
        .catch((error) => {
          this.logError(error);
        });
    },
    downloadVisit() {
      try {
        let startDateArray = moment(this.visit.started_at)
          .format("YYYY-MM-DD")
          .split("-")
          .map((row) => toNumber(row));

        let startTimeArray = moment(this.visit.started_at)
          .format("HH:mm")
          .split(":")
          .map((row) => toNumber(row));

        let endDateArray = moment(this.visit.finished_at)
          .format("YYYY-MM-DD")
          .split("-")
          .map((row) => toNumber(row));

        let endTimeArray = moment(this.visit.finished_at)
          .format("HH:mm")
          .split(":")
          .map((row) => toNumber(row));

        if (startTimeArray[0] == "00") {
          startTimeArray = new Array();
        }

        const visit = {
          start: concat(startDateArray, startTimeArray),
          end: concat(endDateArray, endTimeArray),
          title: this.ticket.title,
          description: this.visit.instruction,
          location: this.detail.property.property_address,
          url: process.env.VUE_APP_BASE_URL,
          geo: {
            lat: toNumber(this.detail.property.latitude),
            lon: toNumber(this.detail.property.longitude),
          },
          categories: this.detail.ticket_type,
          status: "CONFIRMED",
          organizer: {
            name: this.visit.added_by.display_name,
            email: this.visit.added_by.user_email,
          },
          attendees: this.visit.engineers.map((row) => {
            if (row.engineer) {
              return {
                name: row.engineer.full_name,
                email: row.engineer.primary_email,
              };
            }
          }),
        };
        downloadEvent(visit);
      } catch (error) {
        console.log({ error });
        ErrorEventBus.$emit("update:error", InitializeError(error));
      }
    },
    updateStatus(status, reverse_contract = false) {
      if (this.statusLoading) {
        return false;
      }
      this.statusLoading = true;
      this.$store
        .dispatch(PATCH, {
          url: "visit/" + this.visitId + "/status",
          data: {
            status,
            reverse_contract,
            cancel_reason: this.cancel_reason,
            cancel_reason_type: this.cancel_reason_type,
          },
        })
        .then(() => {
          this.getVisit();
          this.confirm_dialog = false;
          this.accept_dialog = false;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.statusLoading = false;
        });
    },
    downloadSchedule() {
      const { id } = this.detail;
      const token = JwtService.getToken();
      const url = `${this.$apiURL}job/${id}/download-schedule?token=${token}`;
      window.open(url, "_blank");
    },
    downloadPDF() {
      const { id } = this.visit;
      const token = JwtService.getToken();
      const url = `${this.$apiURL}visit/${id}/pdf/download?token=${token}`;
      window.open(url, "_blank");
    },
    printPDF() {
      window.open(this.visit.pdf_url, "_blank");
    },
    routeToQuotation() {
      const { billing, billing_person, customer, property, property_person } =
        this.detail;
      this.$router.push(
        this.getDefaultRoute("quotation.create", {
          query: {
            ticket: this.ticketId,
            visit: this.visitId,
            customer: customer ? customer.id : null,
            property_contact_person: property_person
              ? property_person.id
              : null,
            billing_contact_person: billing_person ? billing_person.id : null,
            property: property ? property.id : null,
            billing: billing ? billing.id : null,
          },
        })
      );
    },
    routeToInvoice() {
      const { billing, billing_person, customer, property, property_person } =
        this.detail;
      this.$router.push(
        this.getDefaultRoute("invoice.create", {
          query: {
            ticket: this.ticketId,
            visit: this.visitId,
            customer: customer ? customer.id : null,
            property_contact_person: property_person
              ? property_person.id
              : null,
            billing_contact_person: billing_person ? billing_person.id : null,
            property: property ? property.id : null,
            billing: billing ? billing.id : null,
          },
        })
      );
    },
    getEngineers(id) {
      this.$store
        .dispatch(GET, { url: "job/" + id + "/engineers" })
        .then(({ data }) => {
          this.teamEngineers = data.rows;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.pageLoaded = true;
        });
    },
    getVisit() {
      this.$store
        .dispatch(GET, { url: "visit/v1/" + this.visitId })
        .then(({ data }) => {
          this.starTimereschdule = moment(data.visit.started_at).format(
            "hh:mm A"
          );
          this.endTimereschdule = moment(data.visit.finished_at).format(
            "hh:mm A"
          );

          this.detail = data.ticket;
          console.log(this.detail, "this.detail");
          this.company_email = data.company_email;
          this.customer_email = data.customer_email;
          this.userArr = data.userArr;
          this.visit_reschdule_count = data.visit_reschdule_count;
          this.visit = data.visit;
          this.ticket = data.ticket;
          this.getEngineers(this.ticket.id);
          this.paymentmodes = data.resultpayment_modes;
          if (this.detail.accept_term == 1) {
            this.detail.accept_term = true;
          }
          if (this.detail.accept_term == 0) {
            this.detail.accept_term = false;
          }

          this.ticketId = toSafeInteger(this.detail.id);
          //this.getClosers();
          this.visitId = toSafeInteger(this.visit.id);
          this.getServiceForms(this.ticketId);
        /*   this.getActiveTimer(); */
          // this.getAmount();

          this.moreActions = data.visit.more_actions;

          if (this.visit.engineers && this.visit.engineers.length) {
            this.assigned_team = this.visit.engineers.map(
              (row) => row.engineer
            );
          }

          this.$nextTick(() => {
            this.getLineItems({
              job: this.ticketId,
              visit: this.visitId,
            });
          });

          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Delivery", route: "delivery" },
            { title: "Detail" },
            { title: this.visit.barcode },
          ]);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.pageLoaded = true;
          /* this.getActiveTimer(); */
        });
    },
    routeToUpdate() {
      this.$router.push(
        this.getDefaultRoute("delivery.update", {
          param: this.visitId,
          query: {
            customer: ObjectPath.get(this.detail, "customer.id", null), // customer ? customer.id : null
            property: ObjectPath.get(this.detail, "property.id", null),
            billing: ObjectPath.get(this.detail, "billing.id", null),
            billing_person: ObjectPath.get(
              this.detail,
              "billing_person.id",
              null
            ),
            property_person: ObjectPath.get(
              this.detail,
              "property_person.id",
              null
            ),
            pic_user_id: ObjectPath.get(this.detail, "pic_user_id", null),
            pic_address: ObjectPath.get(this.detail, "pic_address", null),
            module_type: "delivery",
            "do-update": 1,
            job_id: ObjectPath.get(this.detail, "id", null),
            "job-type":
              ObjectPath.get(this.detail, "type", 1) == 1
                ? "one-off"
                : "recurring",
          },
        })
      );
    },
  },
  created() {
    this.visitId = toSafeInteger(this.$route.params.id);
  },
  mounted() {
    this.getVisit();
    this.checkTimerStatus();
    this.updateTimerDuration();
    // this.getAmount();

   /* this.getActiveTimer();*/

    /*ServiceFormEventBus.$on("save:service-form", () => {
      this.getActiveTimer();
    });*/
    ServiceFormEventBus.$on("refresh", () => {
      // this.getAmount();
    });

    this.follow_up_route = this.$route.meta.menuActive;
    /* console.log(this.$route.meta.menuActive); */
  },
  beforeDestroy() {
    clearInterval(this.timeInterval);
    clearInterval(this.durationInterval);
  },
  computed: {
    is_staff() {
      const user = currentUser();
      const role_name = this.lodash.toLower(user.role_name);
      return role_name == "admin";
    },
    is_staff_login() {
      const user = currentUser();
      const role_engineer = this.lodash.toLower(user.engineer);

      return role_engineer == role_engineer;
    },

    /*  getAppointmentText() {
       if (isEmpty(this.visit) === false) {
         let date = this.formatDate(this.visit.started_at);
         let startTime = moment(this.visit.started_at).format("hh:mm A");
         let enddate = this.formatDate(this.visit.finished_at);
         let endTime = moment(this.visit.finished_at).format("hh:mm A");
         return `${date} ${startTime} - ${enddate} ${endTime}`;
       }
       return null;
     }, */
    getStartTime() {
      if (
        moment(this.visit.started_at).format("HH:mm:ss") == "00:00:00" &&
        moment(this.visit.finished_at).format("HH:mm:ss") == "00:00:00"
      ) {
        return "";
      }
      let startTime = moment(this.visit.started_at).format("hh:mm A");
      return startTime;
    },
    getEndTime() {
      if (
        moment(this.visit.finished_at).format("HH:mm:ss") == "00:00:00" &&
        moment(this.visit.started_at).format("HH:mm:ss") == "00:00:00"
      ) {
        return "";
      }
      let endTime = moment(this.visit.finished_at).format("hh:mm A");
      return endTime;
    },
    actualDateFormate() {
      if (this.visit.delivery_date) {
        let actualDate = moment(this.visit.delivery_date).format("L hh:mm A");
        return actualDate;
      } else {
        return false;
      }
    },
    getAppointmentText() {
      if (isEmpty(this.visit) === false) {
        let date = this.formatDate(this.visit.started_at);
        let startTime = moment(this.visit.started_at).format("hh:mm A");
        /*    let enddate = this.formatDate(this.visit.started_at); */
        let endTime = moment(this.visit.finished_at).format("hh:mm A");
        return `${date} ${startTime} - ${endTime}`;
      }
      return null;
    },
    updateStartDate() {
      if (isEmpty(this.visit) === false) {
        return moment(this.visit.started_at).format("YYYY-MM-DD");
      }
      return null;
    },
    updateEndDate() {
      if (isEmpty(this.visit) === false) {
        return moment(this.visit.finished_at).format("YYYY-MM-DD");
      }
      return null;
    },
    updateStartTime() {
      if (isEmpty(this.visit) === false) {
        return moment(this.visit.started_at).format("hh:mm A");
      }
      return null;
    },
    updateEndTime() {
      if (isEmpty(this.visit) === false) {
        return moment(this.visit.finished_at).format("hh:mm A");
      }
      return null;
    },
    getScheduleType() {
      if (this.detail.type === 1) {
        return "One-Off Job";
      }
      if (this.detail.type === 2) {
        return "Recurring Job";
      }
      return null;
    },
  },
  components: {
    Dialog,
    Priority,
    CreateCloser,
    CustomStatus,
    FileTemplate,
    DetailTemplate,
    Datepicker,
    Barcode,
    ChatNote,
    // Timepicker,
    /*  InternalInvoice,
    InternalQuotation, */
    DeliveryLineItem,
    PendingLineItem,
    DeliveriesTab,
    ReceivedLineItem,
    VisitTeamDetail,
    VisitTimestamp,
    ServiceFormDetail,
    TicketCloserDetail,
    VisitNotesDetail,
    SendMail,
    ShareServiceReport,
    ReassignSchedule,
    FailedDelivery,
    // SelectTeam,
    InternalHistoryDetail,
    VueTimepicker,
    VisitfollowupDetail,
    VisitListingTemplate,
  },
};
</script>
